.app{
    height: 100vh;
    display: flex;
    background-color: #020230;
    color: white;
}
.main{
    width: 75%;
    background:linear-gradient(to bottom,rgba(0,0,0,0),#020230),
     url('./assets/bg.jpg') center;
     display: flex;
     flex-direction: column;
}
.top{
height: 50%;
position: relative;
}
.bottom{
height: 50%;
}
.timer{
    width: 70px;
    height: 70px;
    border-radius: 50%;
    border: 5px solid white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    font-weight: 700;
    position: absolute;
    bottom: 10px;
    left: 80px;
}
.trivia{
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}
.question{
    width: 80%;
    background:linear-gradient(#100241,black);
    border: 2px solid white;
    text-align: center;
    padding: 20px;
    border-radius: 10px;
    font-size: 20px;
}
.answers{
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}
.answer{
    width: 40%;
    padding: 10px;
    text-align: center;
    background:linear-gradient(#0e0124,#22074d);
    border: 1px solid white;
    border-radius: 15px;
    font-size: 20px;
    font-weight: 300;
    cursor: pointer;
    margin: 0 10px 20px 10px;
}
.answer:hover,
.answer.active{
    background: mediumblue;
}
.answer.correct{
    animation: correct 3s ease forwards;
}
@keyframes correct {
    0%,22%,42%{
        background: mediumblue;
    }
    20%,40%,60%{
        background:linear-gradient(#0e0124,#22074d);
    }
    62%,100%{
        background: green;
    }
}
.answer.wrong{
    animation: wrong 3s ease forwards;
}
@keyframes wrong {
    0%,22%,42%{
        background: mediumblue;
    }
    20%,40%,60%{
        background:linear-gradient(#0e0124,#22074d);
    }
    62%,100%{
        background: crimson;
    }
}
.pyramid{
    width: 25%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.moneyList{
    list-style: none;
    width: 100%;
    padding: 20px;
}
.moneyListItem{
display: flex;
align-items: center;
padding: 5px;
border-radius: 5px;
}
.moneyListItem.active{
background-color: teal;
}
.moneyListItemNumber{
    font-size: 18px;
    font-weight: 100;
    width: 30%;
}
.moneyListItemAmount{
    font-size: 20px;
    font-weight: 300;
}
.endText{
    position: relative;
    top: 0;
    bottom: o;
    left: 0;
    right: 0;
    margin: auto;
}
.start{
    width: 250px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    position: relative;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
   
    margin: auto;
}
.startInput{
    width: 260px;
    height: 40px;
    border: none;
    border-radius: 5px;
    text-align: center;
    font-size: 18px;
}
.startInput:focus{
    outline: none;
}
.startButton{
    width: 260px;
    height: 40px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 20px;
    font-weight: 500;
}